// Here you can add other styles
.btn-primary,
.btn-info,
.btn-warning {
  color: #ffffff;
}
.btn-primary:hover,
.btn-info:hover,
.btn-warning:hover {
  color: #ffffff;
}
.btn-green {
  color: #fff;
  background-color: #1abc9c;
  border-color: #1abc9c;
}

.btn-green:hover,
.btn-green:active,
.btn-green:focus,
.btn-green.active {
  /* let's darken #1abc9c a bit for hover effect */
  background: #13a185;
  color: #ffffff;
  border-color: #13a185;
}
.bg-green {
  background: #13a185;
}

.sensorBox {
  h2 {
    line-height: 20px;
  }
}
